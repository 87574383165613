/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import moment from 'moment';
import { Formik, Form, FieldArray, Field, ErrorMessage } from 'formik';
import { Checkbox, InputLabel, TextField, Box } from '@material-ui/core';

// 3rd party components
import { Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

// Components
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import SelectFormik from '../../../components/SelectFormik';
import MapsFieldArray from './components/MapsFieldArray';
import VetoesFieldArray from './components/VetoesFieldArray';
import StreamList from './components/StreamList';

// utils
import orderAlphabetically from '../../../utils/order-alphabetically';
import getTeamNameById from '../shared/getTeamNameById';
import TournamentServices from '../../../services/tournaments';
import MapsServices from '../../../services/maps';
import { VETO_ACTIONS } from '../../../utils/constants';

// css
import './styles.css';
import MatchParticularity from './components/MatchParticularity';
import PlayerSelector from './components/PlayerSelector';
import PlayersService from '../../../services/players';
import TeamsService from '../../../services/teams';
import styled from 'styled-components';
import MatchesService from '../../../services/matches';
import StreamsService from '../../../services/streams';

class NewMatchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchFinishedTournaments: false,
      teamsAreDisabled: true,
      allPlayers: [],
      allMaps: [],
      allTournaments: [],
      tournamentDetail: {},
      pandaScoreMatches: [],
      pandaScoreSearchName: '',
      pandaScoreSearchSort: '-begin_at',
      isPandaScoreLoading: false,
      allStreams: [],
    };
  }

  async findPandaScoreMatches() {
    const sort = this.state.pandaScoreSearchSort;
    const name = this.state.pandaScoreSearchName;

    return MatchesService.getPandaScoreMatches({
      per_page: 50,
      page: 1,
      search: { name },
      sort,
      filter: { unscheduled: false },
    });
  }

  async searchPandascore() {
    this.setState({ isPandaScoreLoading: true });
    const responsPandaScore = await this.findPandaScoreMatches();

    let pandaScoreMatches = [];
    if (
      responsPandaScore &&
      responsPandaScore.data &&
      responsPandaScore.data.data
    ) {
      pandaScoreMatches = responsPandaScore.data.data;
    }

    this.setState({
      pandaScoreMatches,
      isPandaScoreLoading: false,
    });
  }

  componentDidMount() {
    const tournamentPromise = TournamentServices.getTournaments(1, 999999);
    const mapPromise = MapsServices.getMaps();
    const playersPromise = PlayersService.getPlayers(1, 9999);
    const streamsPromise = StreamsService.getStreams(1, 10000);
    Promise.all([tournamentPromise, mapPromise, playersPromise, streamsPromise])
      .then(values => {
        const [
          responseTournaments,
          responseMaps,
          responsePlayers,
          responseStreams,
        ] = values;

        const allTournaments = responseTournaments.data.data;
        const allMaps = responseMaps.data.data;
        const allPlayers = responsePlayers.data.data.list.sort((a, b) => {
          if (a.playerNickname > b.playerNickname) return 1;
          if (a.playerNickname < b.playerNickname) return -1;
          return 0;
        });
        const allStreams = responseStreams.data.data;

        this.setState({
          allTournaments,
          allMaps,
          allPlayers,
          allStreams,
        });
      })
      .catch(error => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });
      });
  }

  componentWillUpdate(nextProps) {
    if (nextProps.data) {
      if (
        nextProps.data.isFinished === 1 &&
        !this.state.fetchFinishedTournaments
      ) {
        TournamentServices.getTournaments(1, 999999, true).then(
          oldTournaments => {
            const allTournaments = [
              ...oldTournaments.data.data,
              ...this.state.allTournaments,
            ];
            this.setState({ allTournaments, fetchFinishedTournaments: true });
          }
        );
      }

      if (nextProps.data.tournament && this.state.teamsAreDisabled) {
        this._loadSelectedTournamentData(
          nextProps.data.tournament.tournamentId
        );
      }
    }
  }

  _loadSelectedTournamentData = (tournamentId, setFieldValue) => {
    if (setFieldValue) setFieldValue('tournament', tournamentId);
    TournamentServices.getTournamentDetail(tournamentId)
      .then(response => {
        this.setState({
          tournamentDetail: response.data.data,
          teamsAreDisabled: false,
        });
      })
      .catch(error => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });
      });
  };

  setInitialPlayers = async (teamId, teamPosition, setFieldValue) => {
    try {
      const team = await TeamsService.getTeamById(teamId, true);
      const { playerData } = team.data.data;
      if (teamPosition === 'A')
        setFieldValue('players.playersTeamA', playerData);
      if (teamPosition === 'B')
        setFieldValue('players.playersTeamB', playerData);
    } catch (error) {
      console.log(error);
    }
  };

  addStream(streamId, streams, setFieldValue) {
    const parsedId = parseInt(streamId, 10);
    const alreadySelected = streams.includes(parsedId);
    if (streams.length <= 0) {
      setFieldValue('streamId', parsedId);
    }
    if (streamId && !alreadySelected) {
      setFieldValue('streams', [...streams, parsedId]);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  removeStream(streamId, streams, mainStream, setFieldValue) {
    const newStreamArray = streams.filter(element => element !== streamId);
    const nextMainStream = streams.length > 0 ? newStreamArray[0] : 0;
    if (mainStream.toString() === streamId.toString())
      setFieldValue('streamId', nextMainStream);
    setFieldValue('streams', newStreamArray);
  }

  streamData(streamId) {
    const streams = this.state.allStreams || [];
    const [streamData] = streams.filter(stream => {
      return stream.streamId === streamId;
    });
    return streamData;
  }

  streamFieldStatus = isStreamed => {
    return isStreamed;
  };

  setInitialBool(value, base) {
    return typeof value === 'number' ? !!value : base;
  }

  formikInit(data) {
    return {
      matchDay: data.matchDate
        ? moment(data.matchDate * 1000).format('YYYY-MM-DD')
        : '',
      matchTime: data.matchDate
        ? moment(data.matchDate * 1000).format('HH:mm')
        : '',
      particularity: data.matchParticularity || '',
      tournament: data.tournament ? data.tournament.tournamentId : '',
      streamId: data.mainStream ? data.mainStream.streamId : 0,
      streams: data.streams ? data.streams.map(stream => stream.streamId) : [],
      teamA: data.teamA ? data.teamA.teamId : '',
      teamB: data.teamB ? data.teamB.teamId : '',
      isTBA: this.setInitialBool(data.isTBA, false),
      skipOneSignal: this.setInitialBool(data.skipOneSignal, false),
      isStreamed: this.setInitialBool(data.isStreamed, true),
      isFeatured: this.setInitialBool(data.isFeatured, false),
      isOver: this.setInitialBool(data.isOver, false),
      isFinished: this.setInitialBool(data.isFinished, false),
      scores: data.scores
        ? data.scores.map(score => ({
            scoreA: score.scoreA,
            scoreB: score.scoreB,
            map: score.mapId,
          }))
        : [],
      seriesScoreA: data.seriesScoreA || 0,
      seriesScoreB: data.seriesScoreB || 0,
      vetoes: data.vetoes
        ? data.vetoes.map(veto => ({
            teamId: veto.teamId || 'N/A',
            action: (() => {
              if (veto.isBanned) return VETO_ACTIONS.BAN;
              if (veto.isPicked) return VETO_ACTIONS.PICK;
              if (veto.isLeft) return VETO_ACTIONS.LEFT;
              if (veto.isRandom) return VETO_ACTIONS.RANDOM;

              return VETO_ACTIONS.PICK;
            })(),
            map: veto.map.mapId,
          }))
        : [],
      players: {
        playersTeamA: data.players
          ? data.players
              .filter(player => player.teamId === data.teamA.teamId)
              .map(({ player }) => player)
          : [],
        playersTeamB: data.players
          ? data.players
              .filter(player => player.teamId === data.teamB.teamId)
              .map(({ player }) => player)
          : [],
      },
      isHidden: this.setInitialBool(data.isHidden, false),
      isFootball: this.setInitialBool(data.isFootball, false),
      shootoutScoreA: data.shootoutScoreA || 0,
      shootoutScoreB: data.shootoutScoreB || 0,
      isVzone: this.setInitialBool(data.isVzone, false),
      tournamentVzone: data.tournamentVzone || '',
      urlVzone: data.urlVzone || '',
      pandaScoreId: data.pandaScoreId || '',
      pandaScoreName: data.pandaScoreName || '',
    };
  }

  formikValidate() {
    return Yup.object().shape({
      matchDay: Yup.date().required('Data da partida não informada'),
      matchTime: Yup.string().required('Horário da partida não informado'),
      particularity: Yup.string(),
      shootoutScoreA: Yup.number().min(0, 'Valor inválido'),
      shootoutScoreB: Yup.number().min(0, 'Valor inválido'),
      tournamentVzone: Yup.string(),
      urlVzone: Yup.string(),
      tournament: Yup.number().required('Torneio não selecionado'),
      streamId: Yup.number().when('isStreamed', {
        is: true,
        then: Yup.number().required('Stream não informada'),
        otherwise: Yup.number().nullable(),
      }),
      streams: Yup.array(Yup.number().nullable()),
      teamA: Yup.number().required('Equipe A não informada'),
      teamB: Yup.number().required('Equipe B não informada'),
      isTBA: Yup.boolean(),
      skipOneSignal: Yup.boolean(),
      isStreamed: Yup.boolean(),
      isFeatured: Yup.boolean(),
      isOver: Yup.boolean(),
      isFinished: Yup.boolean(),
      scores: Yup.array(
        Yup.object({
          scoreA: Yup.number().min(0, 'Valor inválido'),
          scoreB: Yup.number().min(0, 'Valor inválido'),
          map: Yup.number(),
        })
      ),
      seriesScoreA: Yup.number().nullable(),
      seriesScoreB: Yup.number().nullable(),
      vetoes: Yup.array(
        Yup.object({
          teamId: Yup.string(),
          action: Yup.number(),
          map: Yup.number(),
        })
      ),
      players: Yup.object({
        playersTeamA: Yup.array(),
        playersTeamB: Yup.array(),
      }),
      isHidden: Yup.boolean(),
      isVzone: Yup.boolean(),
      isFootball: Yup.boolean(),
      pandaScoreId: Yup.string(),
      pandaScoreName: Yup.string(),
    });
  }

  handleCreateMatch = (values, formikHelpers) => {
    formikHelpers.setSubmitting(true);

    try {
      this.props.onSubmit(values);
    } catch (err) {
      console.log('Error on create match request:', err);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.pandaScoreSearchName !== this.state.pandaScoreSearchName) {
      return false;
    }
    return true;
  }

  toMatchName(match) {
    let result = '';

    if (match.begin_at) {
      result += `${moment(match.begin_at).format('DD/MM/YYYY HH:mm')} - `;
    } else {
      result += '[Data não definida] - ';
    }

    if (match.opponents && match.opponents.length) {
      result += match.opponents
        .map(({ opponent }) => opponent.name)
        .reduce((a, b) => `${a} vs ${b}`);
    } else {
      result += match.name;
    }

    if (match.league) {
      result += ` - ${match.league.name}`;
    }

    if (match.serie && match.serie.name) {
      result += ` - ${match.serie.name}`;
    } else {
      result += ` - [Não Informada]`;
    }

    if (match.tournament) {
      result += ` - ${match.tournament.name}`;
    }

    return result;
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      this.searchPandascore();
    }
  }

  render() {
    const { data = {}, form, field } = this.props;
    // const { field, setFieldValue, form } = this.props;

    const {
      teamsAreDisabled,
      allPlayers,
      allMaps,
      allTournaments,
      tournamentDetail,
      pandaScoreMatches,
      pandaScoreSearchSort,
      isPandaScoreLoading,
    } = this.state;

    return (
      <Formik
        enableReinitialize
        initialValues={this.formikInit(data)}
        validationSchema={this.formikValidate()}
        onSubmit={this.handleCreateMatch}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          touched,
          isSubmitting,
        }) => {
          return (
            <Form>
              <Row>
                {/* ================================ */}
                {/* STATUS, HORÁRIO, PARTICULARIDADE */}
                {/* ================================ */}
                <Col lg={3} xs={12}>
                  {/* ================================ */}
                  {/* ============ STATUS ============ */}
                  {/* ================================ */}
                  <Card className="NewMatchForm__card" title="Status">
                    <InputLabel>
                      <Checkbox
                        name="isTBA"
                        checked={values.isTBA}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="NewMatchForm__checkbox"
                      />
                      Horário TBA?
                    </InputLabel>
                    <ErrorMessage name="isTBA" />
                    <hr className="NewMatchForm__divider" />
                    <InputLabel>
                      <Checkbox
                        name="isStreamed"
                        className="NewMatchForm__checkbox"
                        checked={values.isStreamed}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Partida será transmitida?
                    </InputLabel>
                    <ErrorMessage name="isStreamed" />
                    <InputLabel>
                      <Checkbox
                        name="isFeatured"
                        checked={values.isFeatured}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="NewMatchForm__checkbox"
                      />
                      Destaque
                    </InputLabel>
                    <ErrorMessage name="isFeatured" />
                    <hr className="NewMatchForm__divider" />
                    <InputLabel>
                      <Checkbox
                        name="isOver"
                        checked={values.isOver}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="NewMatchForm__checkbox"
                      />
                      Partida acabou? (ñ usar em PbP)
                    </InputLabel>
                    <ErrorMessage name="isOver" />
                    <InputLabel>
                      <Checkbox
                        name="isFinished"
                        checked={values.isFinished}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="NewMatchForm__checkbox"
                      />
                      Partida finalizou? (ñ usar em PbP)
                    </InputLabel>
                    <ErrorMessage name="isFinished" />
                    <InputLabel>
                      <Checkbox
                        name="isHidden"
                        checked={values.isHidden}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="NewMatchForm__checkbox"
                      />
                      Esconder Partida?
                    </InputLabel>
                    <ErrorMessage name="isHidden" />
                    <hr className="NewMatchForm__divider" />
                    <InputLabel>
                      <Checkbox
                        name="isVzone"
                        checked={values.isVzone}
                        onChange={e => {
                          handleChange(e);
                          if (e.target.checked) {
                            setFieldValue('isFootball', false); // Desmarca o outro checkbox
                          }
                        }}
                        onBlur={handleBlur}
                        className="NewMatchForm__checkbox"
                      />
                      Partida VZone?
                    </InputLabel>
                    <ErrorMessage name="isVzone" />
                    {values.isVzone && (
                      <Row>
                        <Col lg={6}>
                          <TextField
                            fullWidth
                            value={values.tournamentVzone}
                            name="tournamentVzone"
                            type="string"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Nome camp VZone"
                          />
                        </Col>
                        <Col lg={6}>
                          <TextField
                            fullWidth
                            value={values.urlVzone}
                            name="urlVzone"
                            type="string"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="URL partida VZone"
                          />
                        </Col>
                      </Row>
                    )}
                    <InputLabel>
                      <Checkbox
                        name="isFootball"
                        checked={values.isFootball}
                        onChange={e => {
                          handleChange(e);
                          if (e.target.checked) {
                            setFieldValue('isVzone', false); // Desmarca o outro checkbox
                          }
                        }}
                        onBlur={handleBlur}
                        className="NewMatchForm__checkbox"
                      />
                      Partida Futebol?
                    </InputLabel>
                    {values.isFootball && (
                      <Row>
                        <Col lg={5}>
                          <TextField
                            fullWidth
                            value={values.shootoutScoreA}
                            name="shootoutScoreA"
                            type="number"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Shoot-out A"
                          />
                        </Col>
                        <div className="NewMatchForm__label">
                          <span>vs.</span>
                        </div>
                        <Col lg={5}>
                          <TextField
                            fullWidth
                            value={values.shootoutScoreB}
                            name="shootoutScoreB"
                            type="number"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Shoot-out B"
                          />
                        </Col>
                      </Row>
                    )}
                    {/* <InputLabel>
                      <Checkbox
                        name="skipOneSignal"
                        checked={values.skipOneSignal}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="NewMatchForm__checkbox"
                      />
                      Pular cadastro no OneSignal?
                    </InputLabel> */}
                  </Card>

                  {/* ================================= */}
                  {/* ============ HORÁRIO ============ */}
                  {/* ================================= */}
                  <Card className="NewMatchForm__card" title="Horário">
                    <Row>
                      <Col lg={6}>
                        <TextField
                          name="matchDay"
                          floatingLabelText="Data"
                          fullWidth
                          type="date"
                          className="NewMatchForm__text-field"
                          value={values.matchDay}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.matchDay && Boolean(errors.matchDay)}
                          helperText={touched.matchDay && errors.matchDay}
                        />
                        <ErrorMessage name="matchDay" />
                      </Col>

                      <Col lg={6}>
                        <TextField
                          name="matchTime"
                          floatingLabelText="Horário"
                          fullWidth
                          type="time"
                          value={values.matchTime}
                          className="NewMatchForm__text-field"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.matchTime && Boolean(errors.matchTime)}
                          helperText={touched.matchTime && errors.matchTime}
                        />
                        <ErrorMessage name="matchTime" />
                      </Col>
                    </Row>
                  </Card>

                  {/* ========================================= */}
                  {/* ============ PARTICULARIDADE ============ */}
                  {/* ========================================= */}
                  <Card className="NewMatchForm__card" title="Particularidade">
                    <Field
                      name="particularity"
                      component={MatchParticularity}
                    />
                  </Card>
                </Col>

                <Col lg={9} xs={12}>
                  <Row>
                    <Col>
                      <Card
                        className="NewMatchForm__card"
                        title="Vínculo PandaScore"
                      >
                        <Row>
                          <Col lg="6">
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              label="Palavra-chave"
                              labelId="pandascore-name-label"
                              onKeyDown={event => this.handleKeyDown(event)}
                              onChange={event => {
                                const name = event.target.value;
                                this.setState({ pandaScoreSearchName: name });
                              }}
                            />
                          </Col>
                          <Col lg="3">
                            <SelectFormik
                              label="Ordenação"
                              value={pandaScoreSearchSort}
                              onChange={e => {
                                const sort = e.target.value;
                                this.setState({ pandaScoreSearchSort: sort });
                              }}
                            >
                              <option value="begin_at">
                                Data início Crescente
                              </option>
                              <option value="-begin_at">
                                Data início Decrescente
                              </option>
                              <option value="name">Nome Crescente</option>
                              <option value="-name">Nome Decrescente</option>
                            </SelectFormik>
                          </Col>

                          <Col lg="2">
                            <Button
                              skin="accent"
                              size="small"
                              block
                              type="button"
                              text="Buscar"
                              onClick={() => this.searchPandascore()}
                              icon={isPandaScoreLoading ? 'spinner' : ''}
                              spin
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="9">
                            <SelectFormik
                              name="pandaScoreId"
                              label="Partida no Panda Score"
                              onChange={e => {
                                const pandaScoreId = e.target.value;
                                setFieldValue('pandaScoreId', pandaScoreId);
                                const match = pandaScoreMatches.find(
                                  pMatch => pMatch.id == pandaScoreId
                                );
                                setFieldValue(
                                  'pandaScoreName',
                                  match ? this.toMatchName(match) : ''
                                );
                              }}
                              value={values.pandaScoreId}
                              className="NewMatchForm__text-field"
                              onBlur={handleBlur}
                              error={
                                touched.pandaScoreId &&
                                Boolean(errors.pandaScoreId)
                              }
                              helperText={
                                touched.pandaScoreId && errors.pandaScoreId
                              }
                            >
                              <option
                                selected
                                label="Clique em buscar e selecione a partida aqui"
                              />
                              {pandaScoreMatches &&
                                pandaScoreMatches.length > 0 &&
                                pandaScoreMatches.map(match => (
                                  <option key={`${match.id}`} value={match.id}>
                                    {this.toMatchName(match)}
                                  </option>
                                ))}
                            </SelectFormik>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={9}>
                            Partida vinculada:{' '}
                            <strong style={{ color: 'green' }}>
                              {values.pandaScoreId} - {values.pandaScoreName}
                            </strong>
                          </Col>

                          <Col lg={2}>
                            <Button
                              skin="error"
                              size="small"
                              block
                              type="button"
                              text="Desvincular"
                              onClick={() => {
                                setFieldValue('pandaScoreId', '');
                                setFieldValue('pandaScoreName', '');
                              }}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} xs={12}>
                      <Card
                        className="NewMatchForm__card"
                        title="Torneio e Stream"
                      >
                        <SelectFormik
                          name="tournament"
                          label="Torneio"
                          onChange={e => {
                            const tournamentId = e.target.value;
                            this._loadSelectedTournamentData(
                              tournamentId,
                              setFieldValue
                            );

                            setFieldValue('teamA', '');
                            setFieldValue('teamB', '');
                            setFieldValue('scores', []);
                            setFieldValue('vetoes', []);
                            setFieldValue('players.playersTeamA', []);
                            setFieldValue('players.playersTeamB', []);
                            setFieldValue('mainStream', 0);
                            setFieldValue('streams', []);
                          }}
                          value={values.tournament}
                          className="NewMatchForm__text-field"
                          onBlur={handleBlur}
                          error={
                            touched.tournament && Boolean(errors.tournament)
                          }
                          helperText={touched.tournament && errors.tournament}
                        >
                          <option value="" selected />
                          {allTournaments &&
                            allTournaments.length > 0 &&
                            allTournaments.map(tournament => (
                              <option
                                key={tournament.tournamentId}
                                value={tournament.tournamentId}
                              >
                                {tournament.tournamentName}
                              </option>
                            ))}
                        </SelectFormik>
                        <SelectFormik
                          disabled={!values.isStreamed}
                          label="Stream"
                          className="NewMatchForm__text-field"
                          onChange={e => {
                            const targetStream = e.target.value;
                            if (!values.streamId) {
                              setFieldValue('streamId', targetStream);
                            }
                            this.addStream(
                              targetStream,
                              values.streams,
                              setFieldValue
                            );
                          }}
                        >
                          <option value="" />
                          {this.state.allStreams
                            .sort(orderAlphabetically('streamName'))
                            .map(stream => (
                              <option
                                value={stream.streamId}
                                key={stream.streamId}
                              >
                                {stream.streamInternalName
                                  ? `${stream.streamInternalName} - ${stream.streamName} - ${stream.streamPlatform}/${stream.streamChannel}`
                                  : `${stream.streamName} - ${stream.streamPlatform}/${stream.streamChannel}`}
                              </option>
                            ))}
                        </SelectFormik>
                      </Card>
                    </Col>

                    <Col lg={6} xs={12}>
                      <Card
                        className="NewMatchForm__card"
                        title="Streams Selecionadas"
                      >
                        {!this.streamFieldStatus(values.isStreamed) && (
                          <div className="NewMatchForm__emptyCard">
                            Nenhuma Stream Selecionada
                          </div>
                        )}
                        {this.streamFieldStatus(values.isStreamed) &&
                          values.streams &&
                          values.streams.length > 0 && (
                            <StreamList
                              name="streams"
                              value={values.streams}
                              streamData={streamId => this.streamData(streamId)}
                              mainStream={values.streamId}
                              removeStream={targetId =>
                                this.removeStream(
                                  targetId,
                                  values.streams,
                                  values.streamId,
                                  setFieldValue
                                )
                              }
                              selectMainStream={streamId =>
                                setFieldValue('streamId', streamId)
                              }
                            />
                          )}
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Card className="NewMatchForm__card" title="Equipes">
                        <Row>
                          <Col lg={5} xs={10}>
                            <SelectFormik
                              name="teamA"
                              value={values.teamA}
                              disabled={teamsAreDisabled}
                              label="Equipe A"
                              className="NewMatchForm__text-field"
                              onChange={e => {
                                this.setInitialPlayers(
                                  e.target.value,
                                  'A',
                                  setFieldValue
                                );
                                return handleChange(e);
                              }}
                              onBlur={handleBlur}
                              error={touched.teamA && Boolean(errors.teamA)}
                              helperText={touched.teamA && errors.teamA}
                            >
                              <option value="" selected />
                              {tournamentDetail &&
                                tournamentDetail.teams &&
                                tournamentDetail.teams.length > 0 &&
                                tournamentDetail.teams
                                  .sort(orderAlphabetically('teamName'))
                                  .map(team => (
                                    <option
                                      key={team.teamId}
                                      value={team.teamId}
                                    >
                                      {team.teamName}
                                    </option>
                                  ))}
                            </SelectFormik>
                          </Col>
                          <Col lg={2}>
                            <div className="NewMatchForm__label">
                              <span>vs.</span>
                            </div>
                          </Col>
                          <Col lg={5} xs={12}>
                            <SelectFormik
                              name="teamB"
                              value={values.teamB}
                              disabled={teamsAreDisabled}
                              label="Equipe B"
                              className="NewMatchForm__text-field"
                              onChange={e => {
                                this.setInitialPlayers(
                                  e.target.value,
                                  'B',
                                  setFieldValue
                                );
                                return handleChange(e);
                              }}
                              onBlur={handleBlur}
                              error={touched.teamB && Boolean(errors.teamB)}
                              helperText={touched.teamB && errors.teamB}
                            >
                              <option value="" selected />
                              {tournamentDetail &&
                                tournamentDetail.teams &&
                                tournamentDetail.teams.length > 0 &&
                                tournamentDetail.teams
                                  .sort(orderAlphabetically('teamName'))
                                  .map(team => (
                                    <option
                                      key={team.teamId}
                                      value={team.teamId}
                                    >
                                      {team.teamName}
                                    </option>
                                  ))}
                            </SelectFormik>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Card className="NewMatchForm__card" title="Mapas">
                        <FieldArray
                          name="scores"
                          render={FieldArrayProps => (
                            <MapsFieldArray
                              {...FieldArrayProps}
                              maps={allMaps}
                              teamA={getTeamNameById(
                                values.teamA,
                                'A',
                                tournamentDetail.teams
                              )}
                              teamB={getTeamNameById(
                                values.teamB,
                                'B',
                                tournamentDetail.teams
                              )}
                            />
                          )}
                        />
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    {values.teamA && values.teamB && (
                      <Col lg={12}>
                        <Card className="NewMatchForm__card" title="Vetos">
                          <FieldArray
                            name="vetoes"
                            render={FieldArrayProps => (
                              <VetoesFieldArray
                                {...FieldArrayProps}
                                value={values.vetoes}
                                maps={allMaps}
                                teamAName={getTeamNameById(
                                  values.teamA,
                                  'A',
                                  tournamentDetail.teams
                                )}
                                teamBName={getTeamNameById(
                                  values.teamB,
                                  'B',
                                  tournamentDetail.teams
                                )}
                                teamA={values.teamA}
                                teamB={values.teamB}
                              />
                            )}
                          />
                        </Card>
                      </Col>
                    )}
                  </Row>

                  {allPlayers.length > 0 && (
                    <StyledCard title="Players">
                      <Field
                        name="players"
                        players={allPlayers}
                        component={PlayerSelector}
                      />
                      <ErrorMessage name="players" component="p" />
                    </StyledCard>
                  )}

                  <Card className="NewMatchForm__card" title="Série">
                    <Row>
                      <Col lg={5}>
                        <TextField
                          name="seriesScoreA"
                          value={values.seriesScoreA}
                          onChange={handleChange}
                          fullWidth
                          type="number"
                          floatingLabelText={getTeamNameById(
                            values.teamA,
                            'A',
                            tournamentDetail.teams
                          )}
                          className="NewMatchForm__text-field"
                        />
                      </Col>
                      <Col lg={2}>
                        <div className="NewMatchForm__label">
                          <span>vs.</span>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <TextField
                          name="seriesScoreB"
                          value={values.seriesScoreB}
                          onChange={handleChange}
                          fullWidth
                          type="number"
                          floatingLabelText={getTeamNameById(
                            values.teamB,
                            'B',
                            tournamentDetail.teams
                          )}
                          className="NewMatchForm__text-field"
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Button
                    type="submit"
                    skin="success"
                    block
                    disabled={
                      isSubmitting || !this.formikValidate().isValidSync(values)
                    }
                    text="Enviar"
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default NewMatchForm;

const StyledCard = styled(Card)`
  margin-bottom: 16px;
`;
