import axios from 'axios';
import moment from 'moment';
import {
  API_BASE_URL,
  API_TS_BASE_URL,
  VETO_ACTIONS,
} from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';

export default class MatchesService {
  matchTypes = {
    NEXT: 'nextMatches',
    FINISHED: 'finishedMatches',
  };

  static getMatchList(
    page = 1,
    amount = 50,
    finished = false,
    featured = false,
    team = null,
    tournament = null,
    showHidden = true
  ) {
    const params = {
      page,
      amount,
      finished: finished ? 1 : 0,
      featured: featured ? 1 : 0,
      team,
      tournament,
      showHidden: showHidden ? 1 : 0,
    };

    const url = `${API_TS_BASE_URL}/matches`;

    return axios.get(url, { params });
  }

  static getMatchDetail(matchId) {
    const url = `${API_TS_BASE_URL}/matches/${matchId}`;

    return axios.get(url);
  }

  static addMatch(values) {
    const formValues = Object.assign({}, values);
    const matchObj = MatchesService.parseMatchObject(formValues);

    try {
      const url = `${API_TS_BASE_URL}/matches`;
      return axios.post(url, matchObj, {
        headers: {
          Authorization: getAuthToken(),
        },
      });
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  static editMatch(values, matchId) {
    const formValues = Object.assign({}, values);
    const matchObj = MatchesService.parseMatchObject(formValues);

    try {
      const url = `${API_TS_BASE_URL}/matches/${matchId}`;
      return axios.put(url, matchObj, {
        headers: {
          Authorization: getAuthToken(),
        },
      });
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  static parseMatchObject(values) {
    const matchObj = values;
    // concat match day and time
    matchObj.matchDate = moment(
      `${matchObj.matchDay} ${matchObj.matchTime}`
    ).format();

    // Add bestOf Field
    matchObj.bestOf = values.scores.length || 0;

    // convert boolean to numbers
    matchObj.isFeatured = matchObj.isFeatured ? 1 : 0;
    matchObj.isFinished = matchObj.isFinished ? 1 : 0;
    matchObj.isOver = matchObj.isOver ? 1 : 0;
    matchObj.isStreamed = matchObj.isStreamed ? 1 : 0;
    matchObj.isTBA = matchObj.isTBA ? 1 : 0;
    matchObj.isHidden = matchObj.isHidden ? 1 : 0;
    matchObj.isVzone = matchObj.isVzone ? 1 : 0;
    matchObj.isFootball = matchObj.isFootball ? 1 : 0;

    // parseInt values
    matchObj.teamA = parseInt(matchObj.teamA, 10);
    matchObj.teamB = parseInt(matchObj.teamB, 10);
    matchObj.tournament = parseInt(matchObj.tournament, 10);
    matchObj.streamId = parseInt(matchObj.streamId, 10);
    matchObj.streams = matchObj.streams.map(stream => parseInt(stream, 10));

    matchObj.seriesScoreA = matchObj.seriesScoreA
      ? parseInt(matchObj.seriesScoreA, 10)
      : 0;
    matchObj.seriesScoreB = matchObj.seriesScoreB
      ? parseInt(matchObj.seriesScoreB, 10)
      : 0;
    matchObj.scores = matchObj.scores.map(score => ({
      map: parseInt(score.map, 10),
      scoreA: parseInt(score.scoreA, 10),
      scoreB: parseInt(score.scoreB, 10),
    }));

    matchObj.playersTeamA = matchObj.players.playersTeamA
      ? matchObj.players.playersTeamA.map(player => player.playerId)
      : [];

    matchObj.playersTeamB = matchObj.players.playersTeamB
      ? matchObj.players.playersTeamB.map(player => player.playerId)
      : [];

    // parse vetoes
    matchObj.vetoes = matchObj.vetoes
      ? matchObj.vetoes.map(veto => ({
          map: parseInt(veto.map, 10),
          teamId: parseInt(veto.teamId, 10) || null,
          isBanned: parseInt(veto.action, 10) === VETO_ACTIONS.BAN,
          isPicked: parseInt(veto.action, 10) === VETO_ACTIONS.PICK,
          isRandom: parseInt(veto.action, 10) === VETO_ACTIONS.RANDOM,
          isLeft: parseInt(veto.action, 10) === VETO_ACTIONS.LEFT,
        }))
      : [];

    return matchObj;
  }

  static getPandaScoreMatches(params) {
    try {
      const url = `${API_TS_BASE_URL}/matches/pandascore/all`;
      return axios.get(url, {
        headers: {
          Authorization: getAuthToken(),
        },
        params,
      });
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  static syncWithPandaScoreMatches(matchId) {
    try {
      const url = `${API_TS_BASE_URL}/matches/${matchId}/sync`;
      return axios.put(
        url,
        {},
        {
          headers: {
            Authorization: getAuthToken(),
          },
        }
      );
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  static async adjustMatchDate(body) {
    try {
      const url = `${API_TS_BASE_URL}/matches/all/adjustmatchdate`;
      return await axios.put(url, body, {
        headers: {
          Authorization: getAuthToken(),
        },
      });
    } catch (error) {
      console.error(error);
      throw new Error(error.response.data.message);
    }
  }

  static clearCache(matchId) {
    const url = `${API_TS_BASE_URL}/matches/${matchId}/clearcache`;

    return axios.get(url, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }
}
