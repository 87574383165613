import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// 3rd
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Select } from '@material-ui/core/';

// Components
import Table from '../../../components/Table';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

// Services
import MatchesService from '../../../services/matches';

// Thunks
import { matchTypes } from '../../../redux/match-list';
import { deleteMatch } from '../../../redux/match-detail';

// Selectors
import getMatchListUi from '../../../redux/selectors/match-list';
import Header from '../../../components/Header';
import { getTournaments, tournamentTypes } from '../../../redux/tournaments';

// css
import './styles.css';
import orderAlphabetically from '../../../utils/order-alphabetically';

class MatchList extends Component {
  tableSelection = [];
  postponeTimes = [
    { value: 5 * 60, label: '+5min' },
    { value: 15 * 60, label: '+15min' },
    { value: 20 * 60, label: '+20min' },
    { value: 30 * 60, label: '+30min' },
    { value: 45 * 60, label: '+45min' },
    { value: 60 * 60, label: '+60min' },
    { value: '', label: '' },
    { value: 5 * -60, label: '-5min' },
    { value: 15 * -60, label: '-15min' },
    { value: 20 * -60, label: '-20min' },
    { value: 30 * -60, label: '-30min' },
    { value: 45 * -60, label: '-45min' },
    { value: 60 * -60, label: '-60min' },
  ];

  constructor(props) {
    super(props);

    this.state = {
      headers: [
        { title: 'ID' },
        { title: 'Horário' },
        { title: 'Status' },
        { title: 'Partida' },
        { title: 'Torneio' },
        { title: 'Score' },
        { title: 'Descrição' },
        { title: 'Ações' },
        { title: '' },
        { title: '' },
      ],
      deleteModalIsVisible: false,
      deleteModalMatchObject: {},
      matchList: [],
      matchSyncId: 0,
      confirmBatchUpdateModalIsVisible: false,
      postponeTime: 0,
    };
    this.tableElementRef = React.createRef();
  }

  componentDidMount() {
    this._getMatchList();
    this._getTournamentList();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.matchType !== prevProps.matchType ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this._getMatchList();
      this._getTournamentList();
    }
  }

  _getMatchList = (tournamentId = null) => {
    const { matchType } = this.props;
    const finished = matchType === matchTypes.FINISHED;
    const featured = matchType === matchTypes.FEATURED;

    MatchesService.getMatchList(
      1,
      50,
      finished,
      featured,
      null,
      tournamentId
    ).then(response => {
      this.setState({
        matchList: response.data.data.list,
      });
    });
  };

  _getTournamentList = () => {
    const { matchType } = this.props;

    const tournamentType =
      matchType === matchTypes.NEXT
        ? tournamentTypes.NEXT
        : tournamentTypes.FINISHED;

    this.props.dispatch(getTournaments(1, 99999, tournamentType));
  };

  _onTournamentChange = ev => {
    if (ev.target.value === 'all') {
      this._getMatchList();

      return;
    }

    this._getMatchList(ev.target.value);
  };
  _formatMatchListArray = () =>
    this.state.matchList.map(match => {
      const matchIsLive =
        moment(match.matchDate * 1000).diff(moment(new Date())) < 0;
      return [
        <Fragment>{match.matchId}</Fragment>,
        moment(match.matchDate * 1000).format('DD[/]MM - HH[:]mm'),
        <Fragment>
          {!match.isOver && !match.isFinished && matchIsLive && (
            <span className="MatchList__label MatchList__live-label">
              AoVivo
            </span>
          )}

          {(!!match.isOver || !!match.isFinished) && (
            <span className="MatchList__label MatchList__finished-label">
              Finalizado
            </span>
          )}

          {!!match.isFeatured && (
            <span className="MatchList__label MatchList__featured-label">
              Destaque
            </span>
          )}

          {!!match.isVzone && (
            <span className="MatchList__label MatchList__vzone-label">
              VZone
            </span>
          )}

          {!!match.isFootball && (
            <span className="MatchList__label MatchList__football-label">
              Futebol
            </span>
          )}

          {!!match.isHidden && (
            <span className="MatchList__label MatchList__hidded-label">
              Escondida
            </span>
          )}

          {!!match.isPlayByPlay && (
            <span className="MatchList__label MatchList__pbp-label">
              PlayByPlay
            </span>
          )}

          {!match.isStreamed && (
            <span className="MatchList__label MatchList__nostreamed-label">
              S/Stream
            </span>
          )}

          {!!match.isTBA && (
            <span className="MatchList__label MatchList__istba-label">TBA</span>
          )}
        </Fragment>,

        <Fragment>
          {match.teamA.teamName} vs {match.teamB.teamName}
        </Fragment>,
        match.tournament.tournamentName,
        `${match.seriesScoreA} x ${match.seriesScoreB}`,
        <Fragment>{match.matchParticularity.substring(0, 32)}</Fragment>,
        <Link
          to={`${process.env.PUBLIC_URL}/u/partidas/editar/${match.matchId}`}
        >
          <Button size="small" text="Editar" block skin="accent" />
        </Link>,
        // <Link
        //   to={`${process.env.PUBLIC_URL}/u/partidas/play-by-play/${match.matchId}`}
        // >
        //   <Button size="small" text="Play By Play" block skin="main" />
        // </Link>,
        <Button
          size="small"
          onClick={() => {
            this.syncWithPandaScore(match);
          }}
          text="Sinc"
          block
          skin="success"
          disabled={!match.pandaScoreId}
          spin
          icon={this.state.matchSyncId === match.matchId ? 'spinner' : ''}
        />,
        <Button
          size="small"
          onClick={() => {
            this._promptDelete(match);
          }}
          text="Excluir"
          block
          skin="error"
        />,
      ];
    });

  _promptDelete = match => {
    this.setState({
      deleteModalMatchObject: match,
      deleteModalIsVisible: true,
    });
  };

  _closeModal = () => {
    this.setState({
      deleteModalIsVisible: false,
      deleteModalMatchObject: {},
    });
  };

  _closeBacthUpdateModal = () => {
    this.setState({
      confirmBatchUpdateModalIsVisible: false,
      postponeTime: 0,
    });
  };

  _deleteMatch = matchId => {
    const { dispatch } = this.props;

    dispatch(deleteMatch(matchId)).then(
      // Success
      () => {
        toast('Partida excluída com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });

        this._closeModal();
        this._getMatchList();
      },
      // Error
      error => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });

        this._closeModal();
      }
    );
  };

  syncWithPandaScore = async match => {
    this.setState({ matchSyncId: match.matchId });
    try {
      await MatchesService.syncWithPandaScoreMatches(match.matchId);
      toast('Partida syncronizada', {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.SUCCESS,
        hideProgressBar: true,
      });
    } catch (error) {
      toast('Erro ao sincronizar a partida. ' + error.message, {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
      });
    }
    this.setState({ matchSyncId: 0 });
  };

  setTableSelection = selection => {
    this.tableSelection = selection;
  };

  _onPostpone = event => {
    const { target } = event;
    const { value } = target;

    if (!value) return;
    if (this.tableSelection.length) {
      this.setState({
        confirmBatchUpdateModalIsVisible: true,
        postponeTime: value,
      });
    } else {
      window.alert('É necessário selecionar pelo menos uma partida');
    }

    setTimeout(() => {
      target.value = '';
    }, 500);
  };

  _confirmUpdateMatchDate = () => {
    const { postponeTime, matchList } = this.state;

    const ids = this.tableSelection.map(index => matchList[index].matchId);

    MatchesService.adjustMatchDate({
      ids,
      timeToAdd: postponeTime,
    })
      .then(() => {
        toast('Partidas atualizadas com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });

        this._getMatchList();

        if (
          this.tableElementRef &&
          this.tableElementRef.current.clearSelection
        ) {
          this.tableElementRef.current.clearSelection();
        }
      })
      .catch(error => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });
      });

    this._closeBacthUpdateModal();
  };

  render() {
    const {
      deleteModalIsVisible,
      deleteModalMatchObject,
      confirmBatchUpdateModalIsVisible,
    } = this.state;

    return (
      <Fragment>
        <Header title={this.props.pageTitle} />
        {/* ================================== */}
        {/* =========== DELETE MATCH ========= */}
        {/* ================================== */}
        {deleteModalIsVisible && (
          <Modal
            title="Excluir Partida"
            onBackdropClick={this._closeModal}
            renderFooter={() => (
              <Fragment>
                <Button
                  size="small"
                  text="Cancelar"
                  skin="gray"
                  className="TeamList__cancel-delete-team"
                  onClick={this._closeModal}
                />
                <Button
                  size="small"
                  text="Excluir Partida"
                  skin="error"
                  onClick={() => {
                    this._deleteMatch(deleteModalMatchObject.matchId);
                  }}
                />
              </Fragment>
            )}
          >
            Deseja excluir a partida{' '}
            <strong>{`${deleteModalMatchObject.teamA.teamName} vs ${deleteModalMatchObject.teamB.teamName}`}</strong>
            ?
          </Modal>
        )}

        {confirmBatchUpdateModalIsVisible && (
          <Modal
            title="Atualizar as partidas selecionadas?"
            onBackdropClick={this._closeBacthUpdateModal}
            renderFooter={() => (
              <Fragment>
                <Button
                  size="small"
                  text="Cancelar"
                  skin="gray"
                  className="TeamList__cancel-delete-team"
                  onClick={this._closeBacthUpdateModal}
                />
                <Button
                  size="small"
                  text="Confirmar"
                  skin="success"
                  onClick={() => {
                    this._confirmUpdateMatchDate();
                  }}
                />
              </Fragment>
            )}
          >
            Deseja atualizar as partidas selecionadas?
          </Modal>
        )}

        <div className="MatchList PageContainer">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Link to={`${process.env.PUBLIC_URL}/u/partidas/novo`}>
              <Button size="small" skin="success" text="+ Adicionar Partida" />
            </Link>
            <div className="MatchList__SearchBar">
              <div
                style={{
                  marginRight: 10,
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <small>Alterar:</small>
                <Select onChange={this._onPostpone} native>
                  <option value=""></option>
                  {this.postponeTimes.map(time => (
                    <option value={time.value}>{time.label}</option>
                  ))}
                </Select>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <small>Filtrar:</small>
                <Select onChange={this._onTournamentChange} native>
                  <option value="all">Todos os campeonatos</option>
                  {this.props.tournamentList
                    .sort(orderAlphabetically('tournamentName'))
                    .map(tournament => (
                      <option value={tournament.tournamentId}>
                        {tournament.tournamentName}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="TableContainer">
            <Table
              headers={this.state.headers}
              isFetching={this.props.ui.isFetching}
              data={this._formatMatchListArray()}
              showSelect
              onSelect={selection => this.setTableSelection(selection)}
              ref={this.tableElementRef}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const tournamentTypeKey =
    ownProps.matchType === matchTypes.NEXT
      ? tournamentTypes.NEXT
      : tournamentTypes.FINISHED;

  return {
    tournamentList: state.tournaments[tournamentTypeKey].data,
    ui: getMatchListUi(ownProps.matchType)(state),
  };
}

export default connect(mapStateToProps)(MatchList);
